.form-check {
    input.form-check-input[type="radio"] {
        @include checkbox-or-radio();
        opacity: 0;
        position: absolute;

        ~label {
            &::before {
                border-radius: 50%;
            }

            &::after {
                border-radius: 50%;
                content: '';
            }
        }

        &:hover:not(:disabled)~label,
        &:checked:not(:disabled)~label {
            &::before {
                border-color: $primary;
            }
        }

        &:checked~label::after {
            transform: scale(0.625) !important;
        }
    }
}
