.form-check {
    input.form-check-input[type="checkbox"] {
        @include checkbox-or-radio();

        ~label {
            &::before {
                border-radius: 0.1875rem;
            }

            &::after {
                border-radius: 0.1875rem;
                color: fade-out($white, 1);
                content: '\f00c';
                font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free';
                font-size: 0.75rem;
                font-style: normal;
                font-variant: normal;
                font-weight: 900;
                text-rendering: auto;
            }
        }

        &:hover:not(:disabled)~label::before {
            border-color: $primary;
        }

        &:checked~label::after {
            color: fade-out($white, 0);
            transform: scale(1) !important;
        }
    }
}
