.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.bottom-border-grey {
    border-bottom: solid 0.0625rem #f0f0f0;
}

@mixin ch-shadow {
    box-shadow: 0 0 0.375rem 0.1875rem fade-out($black, 0.95);
}

.ch-shadow {
    @include ch-shadow();
}

.raise {
    z-index: 1;
}

.lower {
    z-index: -1;
}

.raise-2 {
    z-index: 2;
}

.lower-2 {
    z-index: -2;
}

.flex-sticky-top,
.flex-sticky-bottom {
    @extend .mb-auto;
    position: sticky !important;

    &.flex-sticky-top {
        top: 0;
    }

    &.flex-sticky-bottom {
        bottom: 0;
    }
}

.cursor-pointer {
    cursor: pointer;
}
