section.action-panel {
    @include media-breakpoint-up(xl) {
        margin-bottom: auto !important;
        position: sticky !important;
        top: 0;
    }

    @include media-breakpoint-down(lg) {
        @include media-breakpoint-up(lg) {
            margin-bottom: 1rem !important;
        }
    }

    @include media-breakpoint-down(md) {
        @include ch-shadow();
        bottom: 0;
        display: block;
        left: 0;
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
        position: fixed !important;
        right: 0;
        width: 100%;
        z-index: $zindex-fixed;

        .card {
            background: $ch-actions;
            border-bottom-width: 0;
            border-left-width: 0;
            border-radius: 0;
            border-right-width: 0;
            border-top-width: 0;

            .card-body {
                padding: 1rem;
            }

            &.actions-panel-mobile .card-body {
                align-items: center;
                display: flex;
                justify-content: space-between;

                .btn[data-toggle="drawer"] {
                    white-space: nowrap;

                    &:not(.collapsed) {
                        @each $color,
                        $value in $theme-colors {
                            &.btn-#{$color} {
                                background: mix($black, $value, 20%);
                            }
                        }

                        .fas::before {
                            content: '\f078';
                        }
                    }
                }
            }
        }
    }

    dd {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        &:last-child {
            margin-bottom: 0;
        }

        a {
            color: $body-color;
        }
    }
}
