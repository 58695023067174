$enable-responsive-font-sizes: true;

// no idea where i got this color from - it's a random color
$primary: #326e83 !default;

// this color came from Arthur, but it's not the same color that I use for central.
// I'm not sure if there is an offical color, but we should probably try to be consistent...
// anyway, I'm using this color to express Central in the local sites...
$careerhub-central: #47abb2;

// this is actually just setting the seconday color BACK TO THE ORIGINAL color
// the default color for bootstrap, as the chubby one is too light to be useful to anyone...
$secondary: #6c757d !default;

// this is the standard bootstrap 4 input border color
// I only set it here because I want to use it as a theme color
$input-border-color: #b4b4b4 !default;

$background: #fff !default;
$background-alt: #f5f9fa !default;
$background-body: $background-alt !default;

$background-details-alt: #f5f5f5 !default;

$layoutMainNavWidth: 300px;

// max-width of the hero image and the content nav bar
$hero-width: 1600px !default;
// this only affects the text/ buttons inside the nav bar, not the dropdown items
// useful for dark navigation bars
$content-nav-theme-color: $primary !default;
$content-nav-background: $background-alt !default;

$theme-colors: (
    'input': $input-border-color,
    'content-nav': $content-nav-theme-color,
    'central': $careerhub-central,
);

//variables
@import 'chubby/scss/_variables';

//load bootstrap variables, and parse overridden defaults
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_mixins';
@import '~bootstrap/scss/_variables';

$pagination-active-bg: color-yiq($primary);
$pagination-active-color: $primary;
$pagination-active-border-color: $primary;
