html,
body {
    height: 100%;
    background-color: $background-body;
}

code {
    color: #034dbb;
}

.text-pre-wrap {
    white-space: pre-wrap;
}

.text-underline {
    text-decoration: underline;
}

.text-long-title {
    @extend .text-truncate;
    max-width: 300px;
}

.break-word {
    word-wrap: break-word;
}

.word-break-all {
    word-break: break-all;
}

.opacity-0 {
    opacity: 0;
}
.opacity-5 {
    opacity: 0.5;
}
.opacity-1 {
    opacity: 1;
}

.z-1 {
    z-index: 1 !important;
}
.z-2 {
    z-index: 2 !important;
}
.z-3 {
    z-index: 3 !important;
}

.tl-0 {
    top: 0;
    left: 0;
}

.tr-0 {
    top: 0;
    right: 0;
}

.z-100 {
    z-index: 100;
}

html body h1,
html body .h1 {
    font-weight: normal;
    font-size: 1.1rem;
}

// override for html display for events and event requests
html body .event-html-container h1 {
    font-weight: bold;
    font-size: 1.4375rem;
}

// sets to minimum height, otherwise the auto-size will autosize to less than normal inputs
textarea.form-control {
    min-height: $input-height;
}

.h-input {
    min-height: $input-height;
}

.max-length-count {
    position: absolute;
    right: 0;
    top: 0;
}

.hero-width {
    max-width: $hero-width;
}

.hero-banner-default {
    object-fit: cover;
    border-bottom-left-radius: $contentImageRadius;
    border-bottom-right-radius: $contentImageRadius;
    min-height: 300px;
    max-height: 300px;
    width: 100%;
    max-width: 1600px;

    @include media-breakpoint-up(lg) {
        max-height: 500px;
    }
}

.popover {
    @include media-breakpoint-up(sm) {
        max-width: 400px;
    }
}

.register-width {
    max-width: 800px;
}

.bg {
    background-color: $background;
}

.bg-alt {
    background-color: $background-alt;
}

.bg-details-alt {
    background-color: $background-details-alt;
}

.bg-content-nav-override {
    background-color: $content-nav-background;
}

.border-light {
    border-color: #f1f1f1 !important;
}

.border-dashed {
    border-style: dashed;
}

.large {
    font-size: 1.2rem;
}

.xlarge {
    font-size: 1.4rem;
}

.overflow-y {
    overflow-y: auto;
}

.toast-body {
    background-color: #fff;
}

.layout-main-side {
    @extend .d-none;
    @extend .d-md-flex;
    @extend .vh-100;
    @extend .position-fixed;

    width: $layoutMainNavWidth;
}

.layout-main-content {
    @extend .flex-grow-1;

    @include media-breakpoint-up(md) {
        margin-left: $layoutMainNavWidth;
    }

    @media print {
        margin-left: 0;
    }
}

.application-top-nav-title {
    h1,
    a.btn {
        @include media-breakpoint-down(md) {
            font-size: 90%;
        }
    }
}

.dropdown-menu.show {
    @extend .shadow;
}

// helper that uses the syntax of border-* but uses the input color
.border-input {
    border: $input-border-width solid $input-border-color;

    @if $enable-rounded {
        border-radius: $input-border-radius;
    } @else {
        border-radius: 0;
    }
}

.border-input-dashed {
    border: $input-border-width dashed $input-border-color;
}

// used for groups of components to style similar to an input
.fake-form-control {
    @extend .border-input;

    &.is-invalid {
        border-color: $form-feedback-invalid-color !important;
    }
}

// reset chubby titles that arbitrarily reduce label size
.form-group label {
    font-size: 0.9rem;
}

// removing more chubby styles
div.form-check {
    margin-bottom: 0;
}

// chubby uses an "assumed" font-awesome style, but I'm using only svgs
// I don't want to import the entire font.
.form-check input.form-check-input[type='checkbox'] ~ label:after {
    content: '✓'; // though I have no idea just how compatible this icon is...
}

// mimic alert styles if markdown is in an alert
.alert .markdown-anchor {
    @extend .alert-link;
}

// if I need to use markdown in a list bullet point, I want to kill the margin added to the p tag
// that is always
li.markdown-list-hack > p {
    margin-bottom: 0;
}

// creates an outline badge type for each color
// also link-colors
@each $color, $value in $theme-colors {
    .badge-outline-#{$color} {
        @include badge-variant($value);
        background: transparent;
        border-color: $value;
        border-style: solid;
        border-width: 0.05rem;
        color: $value;
    }

    .btn-link-#{$color} {
        color: $value;

        @include hover() {
            color: darken($value, 15%);
        }
    }
}

// this is essentitally re-overriding the uppercase forced and normal font-weight of chubby...
.badge {
    @each $color, $value in $theme-colors {
        &.badge-#{$color} {
            text-transform: none;
            font-weight: 400;
            font-size: 90%;
        }
        &.badge-outline-#{$color} {
            text-transform: none;
            font-weight: 400;
            font-size: 90%;
        }
    }
}

// this is fixing an issue with chubby button styles
// when a button is disabled and is hovered
.btn {
    @each $color, $value in $theme-colors {
        &.btn-#{$color}.disabled:hover,
        &.btn-#{$color}[disabled]:hover {
            background-color: $value;
            cursor: default;
        }

        // fix for outline colors on btn-sm
        &.btn-outline-#{$color}.btn-sm {
            padding: 0.25rem 0.4375rem;
        }
    }
}

.btn-link:focus.btn-no-focus,
.btn-link.focus.btn-no-focus {
    text-decoration: none;

    &:hover,
    &.hover {
        text-decoration: underline;
    }
}

.btn.btn-no-focus,
.btn.btn-no-focus.btn-link {
    box-shadow: none;
    outline: none;
}

button.no-focus {
    outline: none;
}

.list-group {
    &.list-group-flush {
        .list-group-item:not(.active) {
            background-color: transparent;

            &:hover {
                background-color: rgba($primary, 0.09);
            }
        }
    }

    .list-group-item.disabled {
        background-color: $gray-200;
    }
}

.bg-disabled {
    background-color: $gray-200;
}

// this is fixing the chubby close button on modals...
.modal .modal-dialog .modal-content .modal-header .close {
    font-size: 2rem;
    line-height: 1rem;
    @include pl(2);
    @include py(0);
    @include pr(0);
    @include m(0);
}

.modal-open #root {
    filter: blur(3px);
}
.modal-backdrop.show {
    opacity: 0.75;
}

.modal .modal-content.modal-content-reset {
    padding: 0;
    border: none;
    background-color: transparent;
}

.dropdown-toggle.no-icon:after {
    content: none;
}

// Fix for FontAwesome icons (so they are the same width (for lg))
.fa-lg[class^='svg-inline--'] {
    width: 1.05em !important;
}

// react-select fixes
.react-select-container {
    .react-select__control {
        outline: 0;
        position: relative;
        z-index: 2;
        border: $input-border-width solid $input-border-color;
        min-height: $input-height !important;

        &:hover {
            border-color: $input-focus-border-color;
        }
    }

    .react-select__control--is-focused {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        border-color: $input-focus-border-color;
        outline: 0;
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows {
            box-shadow: $input-box-shadow, $input-focus-box-shadow;
        } @else {
            box-shadow: $input-focus-box-shadow;
        }
    }

    .react-select__control--is-disabled {
        background-color: $input-disabled-bg;
    }

    &.is-invalid .react-select__control {
        border-color: $form-feedback-invalid-color;
    }

    .react-select__placeholder {
        color: $input-border-color;
    }

    .react-select__menu {
        z-index: 6;
    }

    // set height for "empty option"
    // doesn't need to be as high as the others, just higher than empty
    .react-select__option {
        min-height: 25px;
    }
}

// react-select-hacks
.react-select-group-header-custom,
.react-select-option-custom {
    &:hover {
        cursor: pointer;
    }
}

.react-select-group-header-custom {
    font-size: 85%;
    font-weight: 500;
    text-transform: uppercase;
    color: #111;
}

.react-select-group-header-custom {
    // these are copied directly from the inline styles creted by react-select normal options (including the hover)
    background-color: transparent;
    // color: inherit;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;

    &:hover {
        background-color: #deebff;
    }
}

.react-select-status-filter {
    .react-select__control {
        height: 28px;
        min-height: 28px !important;
        font-size: 0.85rem;
        box-sizing: content-box;
    }

    .react-select__multi-value__label {
        padding: 1px 1px 1px 6px;
    }

    .react-select__value-container {
        top: -5px;
    }

    .react-select__indicators {
        top: -5px;
        position: relative;
    }
}

.alert-body p {
    @include mb(2);
}

.dropzone-container {
    @include form-control-focus($ignore-warning: true);

    &:focus .dropzone {
        border-color: transparent;
    }

    border: 1px dashed $input-border-color;
    width: 100%;
    padding: 10px;
    font-size: larger;
    text-align: center;
    color: $text-muted;
    cursor: pointer;
    margin-bottom: 2px;

    &:hover,
    &.drag-hover {
        background-color: #f6f6f6;
    }

    .glyphicon {
        font-size: 1.5em;
    }
}

// bit of a hack, but whatever, the styles are better
.pagination-button-group {
    .page-item.active .page-link {
        cursor: pointer;
    }
}

.pagination .page-item.active .page-link {
    background-color: $primary;
    color: color-yiq($primary);

    &:hover {
        background-color: $pagination-hover-bg;
        color: $primary;
        z-index: 3;
    }
}

// this has limited use because it will
// only work on elements with display: block
// and most of my crap is flex these days
.text-first-upper {
    &:first-letter {
        text-transform: uppercase;
    }

    *:first-letter {
        text-transform: uppercase;
    }
}

.content-nav-master {
    @extend .position-sticky;
    @extend .w-100;
    @extend .z-3;

    top: 0;
    background-color: $content-nav-background;
}

.content-nav-slave {
    @extend .position-fixed;
    @extend .z-1;
    @extend .border-bottom;
    @extend .w-100;

    top: 1px;
}

.content-central-link p {
    margin-bottom: 0;
}

// .content-nav-dropdown-container {
//     max-width: 500px;
// }

.content-nav-dropdown-menu {
    max-height: 80vh;
    overflow-y: auto;

    @include media-breakpoint-up(lg) {
        // I chose this pretty specifically so that
        // the dropdown slices straight through the middle of
        // the text in a large list
        max-height: 368px;
        width: 1000px;
    }
}

.force-rounded-bottom {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    @extend .rounded-bottom;
}

// remove corners and top border from all cards directly below tabs
.tab-wrapper {
    > .card,
    > .list-group .list-group-item,
    > thead tr th {
        border-top: none;
        @extend .force-rounded-bottom;
    }
}

// this hides the description when an element is selected in the workgroup select control
// and eventType select control
.react-select__value-container {
    .employer-description-select-control-value {
        display: none;
    }
}

.analtyics-confirmation {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;

    background-color: rgba(0, 0, 0, 0.4);

    .analytics-inner {
        max-width: 600px;
    }
}

// this is a bit of a hack, but it should rarely be used
// it allows the mobile menu to scroll if it ends up larger than
// the screen size (but not exactly)
.mobile-dropdown-menu {
    max-height: 80vh;
    overflow-y: auto;
}

// NOTE: this was stolen from central.
// Wizard tabs
// this is all a bit hacky, and doens't really play nice
// in terms of being "dynamic" and also it terms of simply
// overriding chubby styles, but meft it, it works.
$wizard-inactive-color: #f5f9fa;
$wizard-outline-color: $card-border-color;
$wizard-arrow-active-color: #fff;

$wizard-border-width: 1rem;

.wizard-tabs.nav.nav-tabs {
    margin-bottom: -$wizard-border-width;
    border-left: $wizard-border-width solid $wizard-outline-color;
    margin-left: 0;

    .nav-item {
        background-image: none !important;

        .nav-link {
            margin-left: -$wizard-border-width;
            margin-right: 0 !important;

            border-radius: 0;
            position: relative;
            border-top: $wizard-border-width solid $wizard-outline-color !important;

            padding-left: 1.5rem;
            padding-right: 1rem;

            @include media-breakpoint-up(lg) {
                padding-left: 2rem;
                padding-right: 1rem;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                z-index: 2;
                margin-left: -$wizard-border-width;
            }

            .nav-link-before-inner,
            .nav-link-after-inner {
                position: absolute;
                z-index: 2;
            }

            &:not(.active) {
                background-color: $wizard-inactive-color;

                &:after,
                .nav-link-after-inner {
                    left: 100%;
                    top: $wizard-border-width * 10;
                    border-top-style: solid;
                    border-left-style: solid;
                    border-bottom-style: solid;
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                    border-top-width: $wizard-border-width * 16;
                    border-bottom-width: $wizard-border-width * 16;

                    border-left-width: $wizard-border-width * 10;
                }

                &:after {
                    border-left-color: $wizard-inactive-color;
                }

                .nav-link-after-inner {
                    border-left-color: $wizard-outline-color;
                }
            }

            &.active {
                padding-left: 2rem;
                padding-right: 1rem;
                margin-right: 0;

                &:before,
                &:after,
                .nav-link-before-inner,
                .nav-link-after-inner {
                    top: -$wizard-border-width;
                    border-top-style: solid;
                    border-left-style: solid;
                    border-bottom-style: solid;
                    border-top-width: $wizard-border-width * 30;
                    border-bottom-width: $wizard-border-width * 25;
                    border-left-width: $wizard-border-width * 14;
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                    margin-top: -$wizard-border-width;
                }

                &:before {
                    left: 0;
                    border-left-color: $wizard-inactive-color;
                }

                &:after {
                    left: 100%;
                    border-left-color: $wizard-arrow-active-color;
                }

                .nav-link-before-inner {
                    left: 0;
                    border-left-color: $wizard-outline-color;
                    z-index: 1;
                }

                .nav-link-after-inner {
                    left: 100%;
                    border-left-color: $wizard-outline-color;
                }
            }
        }
    }

    .nav-item:last-child .nav-link:not(.active) {
        &:before,
        &:after,
        .nav-link-before-inner,
        .nav-link-after-inner {
            top: -$wizard-border-width;
            border-top-width: $wizard-border-width * 30;
            border-bottom-width: $wizard-border-width * 25;
            border-left-width: $wizard-border-width * 14;
        }
    }

    .nav-item:first-child {
        .nav-link {
            padding-left: 1rem;
            margin-left: 0;

            &.active {
                border-left-color: transparent;
            }
        }

        .nav-link:before {
            content: none;
        }
        .nav-link-before-inner {
            display: none;
        }
    }

    .nav-link-wizard-label {
        display: none;
    }

    .nav-link.active .nav-link-wizard-label {
        display: inline;
    }

    @include media-breakpoint-up(lg) {
        .nav-link-wizard-label {
            display: inline;
        }
    }
}

// minor fix for AU Central Ad
.particles-container {
    position: relative;

    .particles-js-canvas-el {
        position: absolute;
        @extend .rounded;
    }
}

// sets the max width of the xl modal to slightly smaller than what it normally is
.central-publish-modal-dialog {
    max-width: 900px;
}
