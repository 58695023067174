$modal-header-border-width: 0;
$modal-footer-border-width: 0;

.modal {
    &.fade {
        transition: opacity 0.35s linear;
    }

    @each $color,
    $value in $theme-colors {
        &.modal-#{$color} {
            .modal-dialog {
                .modal-content {
                    background: $value;
                    border: solid 0.0625rem;
                    border-color: mix($black, $value, 11%);
                    color: $white;

                    .modal-header {
                        .close {
                            text-shadow: none;
                        }

                        .modal-title {
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .modal-dialog {
        @include media-breakpoint-up(lg) {
            &.modal-lg {
                max-width: 45rem;
            }

            &.modal-sm {
                max-width: 20.5rem;
            }
        }

        .modal-content {
            border: solid 0.0625rem #f0f0f0;
            color: #111;
            padding: 1rem;

            .modal-header,
            .modal-body,
            .modal-footer {
                padding: 0;
            }

            .modal-header {
                .close {
                    font-size: 2rem;
                }

                .modal-title {
                    font-size: 1.125rem;
                }
            }

            .modal-body {
                font-size: 0.8125rem;
                padding-bottom: 1.75rem;
                padding-top: 0.9375rem;
            }

            // .modal-footer {
            //     .btn {
            //         margin: 0;
            //         margin-left: 1.25rem;
            //     }
            // }
        }
    }
}
